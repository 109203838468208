import  React, { useState } from "react"
import Helmet from "react-helmet"
import {Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Row from "../../../components/row"
import Variables from "../../../components/variables"

            

const ArenaPubs = () => {
const [isChecked0, setIsChecked0] = useState(true); 
const [isChecked1, setIsChecked1] = useState(false);
const [isChecked2, setIsChecked2] = useState(false);
const [isChecked3, setIsChecked3] = useState(false);
const [isChecked4, setIsChecked4] = useState(false);
const [isChecked5, setIsChecked5] = useState(false);
const [isChecked6, setIsChecked6] = useState(false);
const [isChecked7, setIsChecked7] = useState(false);
const [isChecked8, setIsChecked8] = useState(false);
const [isChecked9, setIsChecked9] = useState(false);
const [isChecked10, setIsChecked10] = useState(false);
const [isChecked11, setIsChecked11] = useState(false);	

const handleOnChange0 = () => {
	//select all
	
	if(isChecked0 == false)
		{
		
	   		setIsChecked1(false)
			setIsChecked2(false)
	   		setIsChecked3(false)
	   		setIsChecked4(false)
			setIsChecked5(false)
	   		setIsChecked6(false)
	   		setIsChecked7(false)
			setIsChecked8(false)
	   		setIsChecked9(false)
	   		setIsChecked10(false)
		}
	
	  setIsChecked0(!isChecked0);
	 
};

const handleOnChange1 = () => {
    //etrasimod all
	 if(isChecked1 == false)
	 {
	   setIsChecked0(false)
	   setIsChecked2(true)
	   setIsChecked3(true)
	   setIsChecked4(true)
	 }
	else{
	
	   setIsChecked2(false)
	   setIsChecked3(false)
	   setIsChecked4(false)
	 }
	
	 setIsChecked1(!isChecked1);
 };

  const handleOnChange2 = () => {
 
	if(isChecked2 == false)
		{
			setIsChecked0(false)
		}
	  
	if(isChecked1 == false)
	 {setIsChecked1(false)}
	  
	   setIsChecked2(!isChecked2); 
 };

  const handleOnChange3 = () => {
	
	  if(isChecked3 == false)
		{
			setIsChecked0(false)
		}
	  
	if(isChecked2 == false)
	 {setIsChecked0(false)}
	  
    setIsChecked3(!isChecked3);
};
	
const handleOnChange4 = () => {
	
	  if(isChecked4 == false)
		{
			setIsChecked0(false)
		}
	  
	  
    setIsChecked4(!isChecked4);
};
	

  const handleOnChange5 = () => {
	//olorinab all
	  
	   if(isChecked5 == false)
	 {setIsChecked0(false)
	   setIsChecked6(true)
	   setIsChecked7(true)
	   setIsChecked8(true)
	 }
	else{
		
	   setIsChecked6(false)
	   setIsChecked7(false)
	   setIsChecked8(false)
	 }
	  
    setIsChecked5(!isChecked5);
};


  const handleOnChange6 = () => {
	  if(isChecked6 == false)
		{
			setIsChecked0(false)
		}
	  
	  if(isChecked5 == true)
		{
			setIsChecked5(false)
		}
	  
    setIsChecked6(!isChecked6);
 };

  const handleOnChange7 = () => {
	  if(isChecked7 == false)
		{
			setIsChecked0(false)
		}
	  
	  if(isChecked5 == true)
		{
			setIsChecked5(false)
		}
    setIsChecked7(!isChecked7);
};

  const handleOnChange8 = () => {
	  if(isChecked8 == false)
		{
			setIsChecked0(false)
		}
	  
	  if(isChecked5 == true)
		{
			setIsChecked5(false)
		}
    setIsChecked8(!isChecked8);
 };

  const handleOnChange9 = () => {
	  if(isChecked9 == false)
		{
			setIsChecked0(false)
		}
    setIsChecked9(!isChecked9);
};

  const handleOnChange10 = () => {
	  if(isChecked10 == false)
		{
			setIsChecked0(false)
		}
    setIsChecked10(!isChecked10);
};
	
const handleOnChange11 = () => {
	if(isChecked11 == false)
		{
			setIsChecked0(false)
		}
    setIsChecked11(!isChecked11);
};

	

const [isCheckedCongress,  setIsCheckedCongress]  = useState(true); 	
const [isCheckedCongress0,  setIsCheckedCongress0]  = useState(false); 
const [isCheckedCongress1,  setIsCheckedCongress1]  = useState(false);
const [isCheckedCongress2,  setIsCheckedCongress2]  = useState(false);
const [isCheckedCongress3,  setIsCheckedCongress3]  = useState(false);
const [isCheckedCongress4,  setIsCheckedCongress4]  = useState(false);
const [isCheckedCongress5,  setIsCheckedCongress5]  = useState(false);
const [isCheckedCongress6,  setIsCheckedCongress6]  = useState(false);
const [isCheckedCongress7,  setIsCheckedCongress7]  = useState(false);
const [isCheckedCongress8,  setIsCheckedCongress8]  = useState(false);
const [isCheckedCongress9,  setIsCheckedCongress9]  = useState(false);
const [isCheckedCongress10, setIsCheckedCongress10] = useState(false);
const [isCheckedCongress11, setIsCheckedCongress11] = useState(false);		
const [isCheckedCongress12, setIsCheckedCongress12] = useState(false);
const [isCheckedCongress13, setIsCheckedCongress13] = useState(false);
const [isCheckedCongress14, setIsCheckedCongress14] = useState(false);
const [isCheckedCongress15, setIsCheckedCongress15] = useState(false);	
const [isCheckedCongress16, setIsCheckedCongress16] = useState(false);
const [isCheckedCongress17, setIsCheckedCongress17] = useState(false);
const [isCheckedCongress18, setIsCheckedCongress18] = useState(false);
const [isCheckedCongress19, setIsCheckedCongress19] = useState(false);
const [isCheckedCongress20, setIsCheckedCongress20] = useState(false);
const [isCheckedCongress21, setIsCheckedCongress21] = useState(false);
const [isCheckedCongress22, setIsCheckedCongress22] = useState(false);
const [isCheckedCongress23, setIsCheckedCongress23] = useState(false);
const [isCheckedCongress24, setIsCheckedCongress24] = useState(false);
const [isCheckedCongress25, setIsCheckedCongress25] = useState(false);	
const [isCheckedCongress26, setIsCheckedCongress26] = useState(false);
const [isCheckedCongress27, setIsCheckedCongress27] = useState(false);
const [isCheckedCongress28, setIsCheckedCongress28] = useState(false);
const [isCheckedCongress29, setIsCheckedCongress29] = useState(false);	
const [isCheckedCongress30, setIsCheckedCongress30] = useState(false);		
	
const handleOnChangeCongress = () => {
	//select all
	
	if(isCheckedCongress == false)
		{

setIsCheckedCongress0(false);			
setIsCheckedCongress1(false);
setIsCheckedCongress2(false);
setIsCheckedCongress3(false);
setIsCheckedCongress4(false);
setIsCheckedCongress5(false);
setIsCheckedCongress6(false);
setIsCheckedCongress7(false);
setIsCheckedCongress8(false);
setIsCheckedCongress9(false);
setIsCheckedCongress10(false);
setIsCheckedCongress11(false);		
setIsCheckedCongress12(false);
setIsCheckedCongress13(false);
setIsCheckedCongress14(false);
setIsCheckedCongress15(false);	
setIsCheckedCongress16(false);
setIsCheckedCongress17(false);
setIsCheckedCongress18(false);
setIsCheckedCongress19(false);
setIsCheckedCongress20(false);
setIsCheckedCongress21(false);
setIsCheckedCongress22(false);
setIsCheckedCongress23(false);
setIsCheckedCongress24(false);
setIsCheckedCongress25(false);	
setIsCheckedCongress26(false);
setIsCheckedCongress27(false);
setIsCheckedCongress28(false);
setIsCheckedCongress29(false);	
setIsCheckedCongress30(false);				
		}
	
	  setIsCheckedCongress(!isCheckedCongress);
	 
};	

const handleOnChangeCongress0 = () =>{
if(isCheckedCongress0 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress0(!isCheckedCongress0);
}

const handleOnChangeCongress1 = () =>{
if(isCheckedCongress1 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress1(!isCheckedCongress1);
}

const handleOnChangeCongress2 = () =>{
if(isCheckedCongress2 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress2(!isCheckedCongress2);
}

const handleOnChangeCongress3 = () =>{
if(isCheckedCongress3 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress3(!isCheckedCongress3);
}

const handleOnChangeCongress4 = () =>{
if(isCheckedCongress4 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress4(!isCheckedCongress4);
}

const handleOnChangeCongress5 = () =>{
if(isCheckedCongress5 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress5(!isCheckedCongress5);
}

const handleOnChangeCongress6 = () =>{
if(isCheckedCongress6 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress6(!isCheckedCongress6);
}

const handleOnChangeCongress7 = () =>{
if(isCheckedCongress7 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress7(!isCheckedCongress7);
}

const handleOnChangeCongress8 = () =>{
if(isCheckedCongress8 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress8(!isCheckedCongress8);
}

const handleOnChangeCongress9 = () =>{
if(isCheckedCongress9 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress9(!isCheckedCongress9);
}

const handleOnChangeCongress10 = () =>{
if(isCheckedCongress10 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress10(!isCheckedCongress10);
}

const handleOnChangeCongress11 = () =>{
if(isCheckedCongress11 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress11(!isCheckedCongress11);
}

const handleOnChangeCongress12 = () =>{
if(isCheckedCongress12 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress12(!isCheckedCongress12);
}

const handleOnChangeCongress13 = () =>{
if(isCheckedCongress13 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress13(!isCheckedCongress13);
}

const handleOnChangeCongress14 = () =>{
if(isCheckedCongress14 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress14(!isCheckedCongress14);
}

const handleOnChangeCongress15 = () =>{
if(isCheckedCongress15 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress15(!isCheckedCongress15);
}

const handleOnChangeCongress16 = () =>{
if(isCheckedCongress16 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress16(!isCheckedCongress16);
}

const handleOnChangeCongress17 = () =>{
if(isCheckedCongress17 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress17(!isCheckedCongress17);
}

const handleOnChangeCongress18 = () =>{
if(isCheckedCongress18 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress18(!isCheckedCongress18);
}

const handleOnChangeCongress19 = () =>{
if(isCheckedCongress19 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress19(!isCheckedCongress19);
}

const handleOnChangeCongress20 = () =>{
if(isCheckedCongress20 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress20(!isCheckedCongress20);
}

const handleOnChangeCongress21 = () =>{
if(isCheckedCongress21 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress21(!isCheckedCongress21);
}

const handleOnChangeCongress22 = () =>{
if(isCheckedCongress22 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress22(!isCheckedCongress22);
}

const handleOnChangeCongress23 = () =>{
if(isCheckedCongress23 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress23(!isCheckedCongress23);
}

const handleOnChangeCongress24 = () =>{
if(isCheckedCongress24 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress24(!isCheckedCongress24);
}

const handleOnChangeCongress25 = () =>{
if(isCheckedCongress25 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress25(!isCheckedCongress25);
}

const handleOnChangeCongress26 = () =>{
if(isCheckedCongress26 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress26(!isCheckedCongress26);
}

const handleOnChangeCongress27 = () =>{
if(isCheckedCongress27 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress27(!isCheckedCongress27);
}

const handleOnChangeCongress28 = () =>{
if(isCheckedCongress28 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress28(!isCheckedCongress28);
}

const handleOnChangeCongress29 = () =>{
if(isCheckedCongress29 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress29(!isCheckedCongress29);
}


const handleOnChangeCongress30 = () =>{
if(isCheckedCongress29 == false)
{
setIsCheckedCongress(false)
}
setIsCheckedCongress29(!isCheckedCongress29);
}


	
		
function renderCongressFilteredData()
	{
		
		var checkboxStates = [
			[ isCheckedCongress, ""],
			[ isCheckedCongress0, "2016"],
			[ isCheckedCongress1, "2016-AIBD"],
			[ isCheckedCongress2, "2016-UEG"],
			[ isCheckedCongress3, "2017"],
			[ isCheckedCongress4, "2017-ECCO"],
			[ isCheckedCongress5, "2017-ASPET"],
			[ isCheckedCongress6, "2018"],
			[ isCheckedCongress7, "2018-APS"],
			[ isCheckedCongress8, "2018-ECCO"],
			[ isCheckedCongress9, "2018-IASP"],
			[ isCheckedCongress10, "2019"],
			[ isCheckedCongress11, "2019-AASP"],
			[ isCheckedCongress12, "2019-DDW"],
			[ isCheckedCongress13, "2019-ECCO"],
			[ isCheckedCongress14, "2019-UEG"],
			[ isCheckedCongress15, "2019-WCOG"],
			[ isCheckedCongress16, "2019-CCC"],
			[ isCheckedCongress17, "2019-NASPGHAN"],
			[ isCheckedCongress18, "2020"],
			[ isCheckedCongress19, "2020-ACG"],
			[ isCheckedCongress20, "2020-DDW"],
			[ isCheckedCongress21, "2020-ECCO"],
			[ isCheckedCongress22, "2020-UEG"],
			[ isCheckedCongress23, "2021"],
			[ isCheckedCongress24, "2021-DDW"],
			[ isCheckedCongress25, "2021-ECCO"],
			[ isCheckedCongress26, "2021-UEG"],
			[ isCheckedCongress27, "2021-ACG"],
			[ isCheckedCongress28, "2022"],
			[ isCheckedCongress29, "2022-ECCO"]
			];
		
			var arrayToCheck = [];
		
		
		for(var i = 0; i<checkboxStates.length; i++ )
		{
			
			if(checkboxStates[i][0]== true){ arrayToCheck.push(checkboxStates[i][1])}	
			
		}
		
		if(arrayToCheck.length == 0){ return "";}
	
	return	Variables.congress.filter(item => (  checkStringWithArray(item.filters, arrayToCheck))   ).reverse().map((item) =>							   
<a key={item.key} href={item.link} target="_blank" rel="noreferrer">
<>{item.title}</><>{item.label} 
</><div><hr /><img src={"/images/view-button.svg"} alt="View" /></div></a>
					 );

	}
	
function renderFilteredData()
	{
		var checkboxStates = [
			[isChecked0, ""],
			[isChecked1, "etrasimod"],
			[isChecked2, "etrasimod-preclinical"],
		    [isChecked3, "etrasimod-phase1"],
			[isChecked4, "etrasimod-phase2"],
			[isChecked5, "olorinab"],
			[isChecked6, "olorinab-preclinical"],
			[isChecked7, "olorinab-phase1"],
			[isChecked8, "olorinab-phase2b"],
			[isChecked9, "sphingosine"],
			[isChecked10, "thromboembolic-events"],
			[isChecked11, "ulcerative-colitis"]
			
		];
		var arrayToCheck = [];
		
		
		for(var i = 0; i<checkboxStates.length; i++ )
		{
			
			if(checkboxStates[i][0]== true){ arrayToCheck.push(checkboxStates[i][1])}	
			//alert(checkboxStates[i][0]);
		}
		if(arrayToCheck.length == 0){ return "";}
		
		//if(checkboxStates[0][0]== true){ arrayToCheck.push(checkboxStates[0][1])}
		//alert(checkboxStates.length);
	return	Variables.keyTopic.filter(item => (  checkStringWithArray(item.filters, arrayToCheck))   ).map((item) =>							   
<a key={item.key} href={item.link} target="_blank" rel="noreferrer">
<>{item.title}</><>{item.label} 
</><div><hr /><img src={"/images/view-button.svg"} alt="View" /></div></a>
					 );

	}
	
function checkStringWithArray(theString, theArray)
	{
	if(theString == null) return;
		
		

		for(var i=0; i<theArray.length; i++)
			{
				if( theString.indexOf(theArray[i]) != -1 )
					{ return true;}
			}
			
		return false;
	}



return(
  <Layout>
	<Helmet>
        <script src={'/js/main.js'} type="text/javascript" />
		<script src={'/js/apa.js'} type="text/javascript" />
	    <script src={'/js/filter.js'} type="text/javascript" />
    </Helmet>
    <Seo title="Publications Archive" description="These scientific publications are provided for educational purposes only. They may discuss or mention Arena investigational drugs, which have not been approved by any health authority." />
    <header className="headline">
	<img src={"/images/our-pipeline-gastroenterology.svg"} alt="Our pipeline gastroenterology" />
	</header>
	<section className="wrap"><h2 className="perfectSpace">We’re pursuing every opportunity to develop and bring our investigational drugs <nobr>to patients</nobr></h2><br/></section>
	<section className="wrap">
	<header className="large"><h1><strong>ARENA</strong> PUBLICATIONS ARCHIVE</h1></header>
	<h2>View publications for detailed data on our investigational drugs</h2>
	  <br/>
	<p><strong className="black">These scientific publications are provided for educational purposes only. They may discuss or mention Arena investigational drugs, which have not been approved by any health authority. Additional studies are required to make any definitive conclusions about safety or efficacy.</strong></p>
	<p>While listed posters/abstracts were accepted for presentations at medical congresses, these have not been subjected to full peer review and present only initial, preliminary findings, which may differ from those that could appear in later, complete manuscripts and peer-reviewed publications.</p> 
	</section>
	<section className="spacer"></section>
	<section className="wrap">
	<div className="inline full"><div className="filter-tab-area">
		<div className="tab-instructions" ><p>Choose either <strong>Key Topics</strong> or <strong>Congresses</strong> to search our database.</p>
		</div>
		<div className="tabs">
			<div className="tab selected"><a href="#tab0">Key Topics</a></div><div className="tab"><a href="#tab1">Congresses</a></div>
		</div>
	</div></div>
	<div className="inline full">
	<div className="scrollBox">
	<div className="filter-area"><img src={"/images/filter-open.svg"} className="mobileFilterBtn"/>
	<div className="filter-controls publications">
			  
		      <label className="container" ><strong>Select All</strong>
 			  <input type="checkbox" checked={isChecked0} onChange={handleOnChange0} name="SelectAll" value="SelectAll" id="SelectAll" />
				  <span className="checkmark"></span>
			  </label>
			  <br />
			  <label className="container" ><strong>Etrasimod Studies</strong>
 			  <input type="checkbox" checked={isChecked1}  onChange={handleOnChange1} name="EtrasimodAll" value="EtrasimodAll" id="EtrasimodAll"    />
				  <span className="checkmark"></span>
			  </label>
			  
			  <label className="container" >Preclinical
 			  <input type="checkbox"  checked={isChecked2}  onChange={handleOnChange2} name="EtrasimodPreclinical" value="EtrasimodPreclinical" id="EtrasimodPreclinical"/>
				  <span className="checkmark"></span>
			  </label>
	
			  <label className="container" >Phase 1
 			  <input type="checkbox"  checked={isChecked3}  onChange={handleOnChange3} name="Phase1" value="EtrasimodPhase1" id="EtrasimodPhase1"/>
				  <span className="checkmark"></span>
			  </label>
			  
			  <label className="container" >Phase 2 OASIS
 			  <input type="checkbox" checked={isChecked4}  onChange={handleOnChange4}/>
				  <span className="checkmark"  name="EtrasimodPhase2" value="EtrasimodPhase2" id="EtrasimodPhase2"></span>
			  </label>
	<br />
	
	<label className="container" ><strong>Olorinab Studies</strong>
	<input type="checkbox"  checked={isChecked5} onChange={handleOnChange5} name="OlorinabAll" value="OlorinabAll" id="OlorinabdAll"  />
				  <span className="checkmark"></span>
			  </label>
			  
			  <label className="container" >Preclinical
 			  <input type="checkbox"   checked={isChecked6}  onChange={handleOnChange6} name="OlorinabPreclinical" value="OlorinabPreclinical" id="OlorinabPreclinical" />
				  <span className="checkmark"></span>
			  </label>
	
			  <label className="container" >Phase 1
 			  <input type="checkbox" name="Phase1" value="OlorinabPhase1" id="OlorinabPhase1" checked={isChecked7} onChange={handleOnChange7}/>
				  <span className="checkmark"></span>
			  </label>
			  
			  <label className="container" >Phase 2a
 			  <input type="checkbox"  checked={isChecked8}  onChange={handleOnChange8}/>
				  <span className="checkmark"  name="OlorinabPhase2" value="OlorinabPhase2" id="OlorinabPhase2"></span>
			  </label>
	<br />
	<label className="container" ><strong>Sphingosine-1-Phosphate Modulation</strong>
 			  <input type="checkbox"  checked={isChecked9} onChange={handleOnChange9}/>
				  <span className="checkmark"  name="Sphingosine" value="Sphingosine" id="Sphingosine" ></span>
			  </label>
	
	<br />
			  
	          <label className="container" ><strong>Thromboembolic Events in IBD</strong>
 			  <input type="checkbox"  checked={isChecked10} onChange={handleOnChange10}/>
				  <span className="checkmark"  name="Thromboembolic" value="Thromboembolic" id="Thromboembolic"></span>
			  </label>
	
	<br />
		      <label className="container" ><strong>Ulcerative Colitis</strong>
 			  <input type="checkbox" checked={isChecked11}  onChange={handleOnChange11}/>
				  <span className="checkmark"  name="UlcerativeColitis" value="UlcerativeColitis" id="UlcerativeColitis"></span>
			  </label>
	
	<br />
	
			  
			  
		  </div>
		  <div className="filter-results publications"> 
	{renderFilteredData()}
	
</div>
		  </div>
	    </div>
	<div className="scrollBox hidden">
	<div className="filter-area hidden"><img src={"/images/filter-open.svg"} className="mobileFilterBtn"/>
	<div className="filter-controls publications">
			 <label className="container" ><strong>Select All</strong>
 			  <input type="checkbox" checked="checked"  name="SelectAll2" value="SelectAll2" id="SelectAll2" checked={isCheckedCongress} onChange={handleOnChangeCongress}  />
				  <span className="checkmark"></span>
			  </label>
	<br />
			               <label className="container" ><strong>2021</strong>
 			  	   <input type="checkbox"   checked={isCheckedCongress23} onChange={handleOnChangeCongress23} />
				  <span className="checkmark"></span>
			  </label>

  			  <label className="container" >DDW
 			  <input type="checkbox" checked={isCheckedCongress24} onChange={handleOnChangeCongress24}  />
				  <span className="checkmark" ></span>
			  </label>

              <label className="container" >ECCO
 			  <input type="checkbox" checked={isCheckedCongress25} onChange={handleOnChangeCongress25} />
				  <span className="checkmark" ></span>
			  </label>

              <label className="container" >UEG
 			  <input type="checkbox" checked={isCheckedCongress26} onChange={handleOnChangeCongress26}  />
				  <span className="checkmark" ></span>
			  </label>

<br />		  
					      <label className="container" ><strong>2020</strong>
 			  	   <input type="checkbox" checked={isCheckedCongress18} onChange={handleOnChangeCongress18} />
				  <span className="checkmark"></span>
			  </label>

              <label className="container" >ACG
 			  <input type="checkbox" checked={isCheckedCongress19} onChange={handleOnChangeCongress19}/>
				  <span className="checkmark" ></span>
			  </label>

              <label className="container" >DDW
 			  <input type="checkbox" checked={isCheckedCongress20} onChange={handleOnChangeCongress20} />
				  <span className="checkmark" ></span>
			  </label>

              <label className="container" >ECCO
 			  <input type="checkbox"  checked={isCheckedCongress21} onChange={handleOnChangeCongress21} />
				  <span className="checkmark" ></span>
			  </label>

              <label className="container" >UEG
 			  <input type="checkbox"  checked={isCheckedCongress22} onChange={handleOnChangeCongress22} />
				  <span className="checkmark" ></span>
			  </label>
<br />

				       <label className="container" ><strong>2019</strong>
 			  	   <input type="checkbox" checked={isCheckedCongress10} onChange={handleOnChangeCongress10} />
				  <span className="checkmark"></span>
			  </label>
              
              <label className="container" >AAPS PharmSci 360
 			  <input type="checkbox" checked={isCheckedCongress11} onChange={handleOnChangeCongress11} />
				  <span className="checkmark" ></span>
			  </label>
			  
			   <label className="container" >DDW
 			  <input type="checkbox" checked={isCheckedCongress12} onChange={handleOnChangeCongress12} />
				  <span className="checkmark" ></span>
			  </label>

              <label className="container" >ECCO
 			  <input type="checkbox" checked={isCheckedCongress13} onChange={handleOnChangeCongress13} />
				  <span className="checkmark" ></span>
			  </label>

              <label className="container" >UEG
 			  <input type="checkbox" checked={isCheckedCongress14} onChange={handleOnChangeCongress14} />
				  <span className="checkmark" ></span>
			  </label>

              <label className="container" >WCOG
 			  <input type="checkbox" checked={isCheckedCongress15} onChange={handleOnChangeCongress15} />
				  <span className="checkmark" ></span>
			  </label>

              <label className="container" >Crohn's & Colitis Congress
 			  <input type="checkbox" checked={isCheckedCongress16} onChange={handleOnChangeCongress16} />
				  <span className="checkmark" ></span>
			  </label>

              <label className="container" >NASPGHAN
 			  <input type="checkbox" checked={isCheckedCongress17} onChange={handleOnChangeCongress17} />
				  <span className="checkmark" ></span>
			  </label>

				<br />	 
					  
					  		  
			 <label className="container" ><strong>2018</strong>
 			  	   <input type="checkbox" checked={isCheckedCongress6} onChange={handleOnChangeCongress6} />
				  <span className="checkmark"></span>
			  </label>
              
              <label className="container" >APS
 			  <input type="checkbox" checked={isCheckedCongress7} onChange={handleOnChangeCongress7} />
				  <span className="checkmark" ></span>
			  </label>
             
              <label className="container" >ECCO
 			  <input type="checkbox" checked={isCheckedCongress8} onChange={handleOnChangeCongress8} />
				  <span className="checkmark" ></span>
			  </label>
			  

			 
			 <label className="container" >IASP
 			  <input type="checkbox" checked={isCheckedCongress9} onChange={handleOnChangeCongress9} />
				  <span className="checkmark" ></span>
			  </label>	 
		  <br />
					
               
		      <label className="container" ><strong>2017</strong>
 			  	   <input type="checkbox" checked={isCheckedCongress3} onChange={handleOnChangeCongress3} />
				  <span className="checkmark"></span>
			  </label>

              <label className="container" >ECCO
 			  <input type="checkbox" checked={isCheckedCongress4} onChange={handleOnChangeCongress4} />
				  <span className="checkmark" ></span>
			  </label>
			  

              <label className="container" >ASPET
 			  <input type="checkbox" checked={isCheckedCongress5} onChange={handleOnChangeCongress5} />
				  <span className="checkmark" ></span>
			  </label>
              <br />  
					  
		      <label className="container" ><strong>2016</strong>
 			  <input type="checkbox" name="congress2016" value="congress2016" id="congress2016" checked={isCheckedCongress0} onChange={handleOnChangeCongress0} />
				  <span className="checkmark"></span>
			  </label>
			  
			  <label className="container" >AIBD
 			  <input type="checkbox" name="AIBD-2016" value="AIBD-2016" id="AIBD-2016" checked={isCheckedCongress1} onChange={handleOnChangeCongress1} />
				  <span className="checkmark"></span>
			  </label>
			  
			  <label className="container" >UEG
 			  <input type="checkbox" name="UEG-2016" value="UEG-2016" id="UEG-2016" checked={isCheckedCongress2} onChange={handleOnChangeCongress2}/>
				  <span className="checkmark"></span>
			  </label>
	          <br />
	          
			
					 

       

          
			  
		  </div>
		  <div className="filter-results publications">
	{renderCongressFilteredData()}
		  </div>
	    </div>
	 </div>
	 </div>
	</section>
	  <section className="spacer end"></section>
	<footer className="definitions">AAPS PHARMSCI 360=American Association of Pharmaceutical Scientists PHARMSCI 360 Meeting; ACG=American College of Gastroenterology Annual Scientific Meeting; AIBD=Advances in Inflammatory Bowel Diseases; APS=American Pain Society Scientific Summit; ASPET=American Society for Pharmacology and Experimental Therapeutics Annual Meeting; DDW=Digestive Disease Week; ECCO=Congress of European Crohn’s and Colitis Organisation; FAERS=FDA Adverse Event Reporting System; IASP=International Association for the Study of Pain World Congress on Pain; IBD=inflammatory bowel disease; NASPGHAN=North American Society for Pediatric Gastroenterology, Hepatology & Nutrition Annual Meeting; WCOG=World Congress of Gastroenterology; UEG= United European Gastroenterology Week.<br />
	 <span>Arena is a clinical stage pharmaceutical company that does not have any medicines or products approved for use by any health authority.</span>
</footer>
  </Layout>
)
}

export default ArenaPubs
